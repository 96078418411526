import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCheck, faPencil, faSave} from "@fortawesome/free-solid-svg-icons";
import React, {useMemo, useState} from "react";
import DataService from "../services/DataService";
import {useRecoilState} from "recoil";
import {user as userAtom} from "../atoms/user";
import {Button} from "react-bootstrap";
import {useParams} from "react-router-dom";
import Form from "react-bootstrap/Form";
import SkillSelectbox from "./SkillSelectbox";

const printStat = (baseStat:any, stat:any): any => {
    if(stat == null) {
        return baseStat;
    }
    return <span className={"textRed"}>{stat}</span>;
}

const getValue = (value: any): any => {
    if(value == null) {
        return undefined;
    }
    return value;
}

const emptyToNull = (value: string): string | null => {
    if(value === "") {
        return null;
    }
    return value;
}

const numberEmptyToNull = (value: string): number | null => {
    if(value === "") {
        return null;
    }
    return parseInt(value);
}

export default function PlayerRow(props: PlayerRowProps) {
    // @ts-ignore
    const params: { saison: number } = useParams();
    const service = useMemo(() => DataService.getInstance(), []);
    const [user, setUser] = useRecoilState(userAtom);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [player, setPlayer] = useState<Player>(props.player);
    const [skills, setSkills] = useState<Skill[]>([]);

    return (
        <tr key={player.id}>
            <td className={"textCenter"}>{props.pos}</td>
            <td>{
                // user.rolle === "Admin" && openEdit ?
                //     <Form.Control type="text" defaultValue={player.name} onChange={event => player.name = event.target.value} />
                // :
                    player.name
            }</td>
            <td>{player.position}</td>
            <td>{
                user.rolle === "Admin" && openEdit ?
                    <Form.Control type="number" defaultValue={getValue(player.stats.be)} onChange={event => player.stats.be = numberEmptyToNull(event.target.value)} />
                :
                    printStat(player.baseStats.be, player.stats.be)
            }</td>
            <td>{
                user.rolle === "Admin" && openEdit ?
                    <Form.Control type="number" defaultValue={getValue(player.stats.st)} onChange={event => player.stats.st = numberEmptyToNull(event.target.value)} />
                :
                    printStat(player.baseStats.st, player.stats.st)
            }</td>
            <td>{
                user.rolle === "Admin" && openEdit ?
                    <Form.Control type="text" defaultValue={getValue(player.stats.ge)} onChange={event => player.stats.ge = emptyToNull(event.target.value)} />
                :
                    printStat(player.baseStats.ge, player.stats.ge)
            }</td>
            <td>{
                user.rolle === "Admin" && openEdit ?
                    <Form.Control type="text" defaultValue={getValue(player.stats.wg)} onChange={event => player.stats.wg = emptyToNull(event.target.value)} />
                :
                    printStat(player.baseStats.wg, player.stats.wg)
            }</td>
            <td>{
                user.rolle === "Admin" && openEdit ?
                    <Form.Control type="text" defaultValue={getValue(player.stats.rw)} onChange={event => player.stats.rw = emptyToNull(event.target.value)} />
                :
                    printStat(player.baseStats.rw, player.stats.rw)
            }</td>
            <td>
                {
                    openEdit ?
                        <SkillSelectbox
                            selected={player.stats.skill}
                            onchange={values => {
                                const data: Skill[] = [];
                                player.stats.skill = '';
                                values.forEach((v: { value: any; label: any; }) => {
                                    data.push({id:v.value, name:v.label, value: null});
                                    player.stats.skill += ', ' + v.label;
                                });
                                setSkills(data);
                            }}
                        />
                    :
                        <>
                            {player.baseStats.skill}
                            <span className={"textRed"}>
                            {
                                (player.baseStats.skill !== "" && player.stats.skill !== null)
                                && <>,&nbsp;</>
                            }
                            {player.stats.skill}
                            </span>
                        </>
                }
            </td>
            <td className="textRight">{player.kosten.toLocaleString()}</td>
            <td>{
                openEdit ?
                    <Form.Control type="number" defaultValue={player.ssp} onChange={event => player.ssp = parseInt(event.target.value)} />
                :
                    player.ssp
            }</td>
            <td>
                {
                    user.rolle === "Admin" && openEdit ?
                        <Form.Check.Input defaultChecked={player.vhs}
                                          onChange={event => player.vhs = event.target.checked}
                        />
                    :
                        player.vhs && <FontAwesomeIcon icon={faCheck}/>
                }
            </td>
            <td>
                {
                    user.rolle === "Admin" && openEdit ?
                        <Form.Check.Input defaultChecked={player.hv}
                                          onChange={event => player.hv = event.target.checked}
                        />
                    :
                        player.hv && <FontAwesomeIcon icon={faCheck}/>
                }
            </td>
            <td>
                {
                    user.rolle === "Admin" && openEdit ?
                        <Form.Check.Input defaultChecked={player.ad}
                                          onChange={event => player.ad = event.target.checked}
                        />
                    :
                        player.ad && <FontAwesomeIcon icon={faCheck}/>
                }
            </td>
            <td className="textRight">{
                openEdit ?
                    <Form.Control type="number" defaultValue={player.wert} onChange={event => player.wert = parseInt(event.target.value)} />
                :
                    player.wert.toLocaleString()
            }</td>
            {
                props.edit && (user.rolle === "Admin" || player.ssp >= 6) &&
                <td>
                    {
                        openEdit ?
                            <>
                                <Button variant="primary" size="sm" onClick={(e) => {
                                    setPlayer(props.player);
                                    setOpenEdit(false);
                                }}>
                                    <FontAwesomeIcon icon={faCancel}/>
                                </Button>
                                &nbsp;
                                <Button variant="primary" size="sm" onClick={(e) => {
                                    const obj = {...player, skills: skills}
                                    service.team().updatePlayer(params.saison, user.token, setUser, obj, d => d, b => b);
                                    setOpenEdit(false);
                                }}>
                                    <FontAwesomeIcon icon={faSave}/>
                                </Button>
                            </>
                        :
                            <Button variant="primary" size="sm" onClick={(e) => {
                                setOpenEdit(true)
                            }}>
                                <FontAwesomeIcon icon={faPencil}/>
                            </Button>
                    }
                </td>
            }
        </tr>
    );
}