import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from "react-router-dom";
import DataService from "../../services/DataService";
import {Col, Container, Row} from "react-bootstrap";
import SpieltagTab from "../../component/SpieltagTab";
import PlayerRow from "../../component/PlayerRow";
import {emptyTeamOverview} from "../../util/EmptyObjects";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";

const printEmptyRows = (lenght: number): any => {
    let rows = [];
    for(let i= lenght; i <= 16; i++) {
        rows.push(<tr key={i}>
            <td className={"textCenter"}>{i}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>);
    }
    return rows;
}

export default function Team() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [loading, setLoading] = useState<boolean>(false);
    const [overviewData, setOverviewData] = useState<TeamOverview>(emptyTeamOverview);
    // @ts-ignore
    const params: { saison: number, id: string, spieltag: number | undefined  } = useParams();
    useEffect(() => {
        setLoading(true);
        service.team().get(params.saison, params.id, params.spieltag, setOverviewData, setLoading);
    }, [service, params]);

    return (
        <div className="Team">
            {
                loading &&
                <div className="spinner-grow" role="status"/>
            }
            {
                !loading &&
                <div>
                    <div>
                        <h1 style={{float:"left"}}>{overviewData.name}</h1>
                        &nbsp;&nbsp;
                        <a href={service.team().getPrint(params.saison, params.id, overviewData.spieltag)} rel="noreferrer" target={"_blank"}><FontAwesomeIcon icon={faPrint}/></a>
                    </div>
                    <div style={{clear: "both"}}>
                        <span>Art des Teams:</span>&nbsp;<span>{overviewData.art}</span>
                    </div>
                    <div>
                        <span>Trainer:</span>&nbsp;<span>{overviewData.trainer}</span>
                    </div>
                    <div>
                        <span>Team-Sonderregeln:</span>&nbsp;<span>{overviewData.sonderregeln}</span>
                    </div>
                    <Container fluid>
                        <Row>
                            <Col>
                                <SpieltagTab link={'/saison/'+params.saison+'/team/'+params.id} spieltag={overviewData.spieltag} saison={params.saison} teamId={Number(params.id)}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <table className="col-sm-12 table table-bordered table-striped table-sm">
                                    <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>Name (Spielernummer)</th>
                                        <th>Position</th>
                                        <th>BE</th>
                                        <th>ST</th>
                                        <th>GE</th>
                                        <th>WG</th>
                                        <th>RW</th>
                                        <th>Fertigkeiten</th>
                                        <th>Anwerbekosten</th>
                                        <th>Verf. SSP</th>
                                        <th>VNS</th>
                                        <th>HV</th>
                                        <th>AD</th>
                                        <th>Moment. Wert</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        overviewData.spieler.map((player, index) => <PlayerRow player={player} pos={index+1} edit={false} />)
                                    }
                                    {
                                        printEmptyRows(overviewData.spieler.length+1)
                                    }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <table className="col-sm-12 table table-bordered table-sm">
                                    <tbody>
                                        <tr>
                                            <td>Teamkasse:</td> <td className="textRight">{overviewData.kasse.toLocaleString()}</td>
                                        </tr>
                                        <tr>
                                            <td>Treue Fans:</td> <td className="textRight">{overviewData.fans}</td>
                                        </tr>
                                        <tr>
                                            <td>Touchdowns:</td> <td className="textRight">{overviewData.touchdowns}</td>
                                        </tr>
                                        <tr>
                                            <td>VSA:</td> <td className="textRight">{overviewData.vsa}</td>
                                        </tr>
                                        <tr>
                                            <td>Absolvierte Spiele:</td> <td className="textRight">{overviewData.spiele}</td>
                                        </tr>
                                        <tr>
                                            <td>Ligapunkte:</td> <td className="textRight">{overviewData.punkte}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                            <Col>
                                <table className="col-sm-12 table table-bordered table-sm">
                                    <tbody>
                                    <tr>
                                        <td>Wiederholungswürfe:</td> <td className="textCenter">{overviewData.rerolls.anzahl}</td> <td className="textCenter">X</td> <td className="textRight">{overviewData.rerolls.kosten.toLocaleString()}</td> <td className="textRight">{(overviewData.rerolls.anzahl * overviewData.rerolls.kosten).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>Trainerassistenten:</td> <td className="textCenter">{overviewData.trainerass}</td> <td className="textCenter">X</td> <td className="textRight">10.000</td> <td className="textRight">{(overviewData.trainerass * 10000).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>Cheerleader:</td> <td className="textCenter">{overviewData.cheerleader}</td> <td className="textCenter">X</td> <td className="textRight">10.000</td> <td className="textRight">{(overviewData.cheerleader * 10000).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>Sanitäter:</td> <td className="textCenter">{overviewData.sani}</td> <td className="textCenter">X</td> <td className="textRight">50.000</td> <td className="textRight">{(overviewData.sani * 50000).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>Teamwert:</td> <td colSpan={4} className="textRight">{overviewData.value.all.toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>Momentaner Teamwert:</td> <td colSpan={4} className="textRight">{overviewData.value.actual.toLocaleString()}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </div>
    );
}