import BaseService from "./BaseService";

export default class TeamService extends BaseService {

    public getAll(saison: number, setOverview: (p:Team[]) => void, setLoading: (p:boolean) => void): void {
        fetch(this.getUrl(saison)+"team")
            .then(r => r.json() as Promise<ApiResponse<Team[]>>)
            .then(r => setOverview(r.data))
            .finally(() => setLoading(false));
    }

    public get(saison: number, id: string, spieltag:number | undefined, setOverview: (p:TeamOverview) => void, setLoading: (p:boolean) => void): void {
        let url = this.getUrl(saison)+"team/"+id;
        if(spieltag) {
           url += "/" + spieltag;
        }
        fetch(url)
            .then(r => r.json() as Promise<ApiResponse<TeamOverview>>)
            .then(r => setOverview(r.data))
            .finally(() => setLoading(false));
    }

    public getPrint(saison: number, id: string, spieltag:number):string {
        return this.getUrl(saison)+"team/"+id+"/"+spieltag+"/print";
        // fetch()
        // .then(r => r.blob())
        // .then(blob => {
        //     // @ts-ignore
        //     const url = window.URL.createObjectURL(new Blob([blob]), {type: "application/pdf"});
        //     //window.open(url);
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute(
        //          'download',
        //          'team.pdf'
        //      );
        //      document.body.appendChild(link);
        //      link.click();
        //      // @ts-ignore
        //      link.parentNode.removeChild(link);
        // });
    }

    public getPlayer(saison: number, id: number, spieltag:number, setPlayer: (p:BasePlayer[]) => void, setLoading: (p:boolean) => void): void {
        fetch(this.getUrl(saison)+"team/"+id+"/"+spieltag+"/player")
            .then(r => r.json() as Promise<ApiResponse<BasePlayer[]>>)
            .then(r => setPlayer(r.data))
            .finally(() => setLoading(false));
    }

    public getTabDaten(saison: number, id: number, setData: (p:Number[]) => void, setLoading: (p:boolean) => void): void {
        fetch(this.getUrl(saison)+"team/"+id+"/tab")
            .then(r => r.json() as Promise<ApiResponse<Number[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getNewMember(saison: number,id: number, setData: (p:Player[]) => void, setLoading: (p:boolean) => void): void {
        fetch(this.getUrl(saison)+"team/"+id+"/member")
            .then(r => r.json() as Promise<ApiResponse<Player[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    // Edit
    public getEditTeam(saison: number, token: string, setUser: (u:User) => void, setData: (d: TeamOverview) => void, setLoading: (p:boolean) => void, teamId: number = 0): void {
        const authToken = 'Bearer ' + token;
        let path = this.getUrl(saison)+"member/team";
        if(teamId !== 0) {
            path += "/"+teamId;
        }
        fetch(path, {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            }
        })
        .then(r => {
            if(r.ok) {
                return r.json() as Promise<ApiResponse<TeamOverview>>;
            } else {
                return Promise.reject(r);
            }
        })
        .then(r => setData(r.data))
        .catch(e => setUser({token: "", name: "", rolle: ""}))
        .finally(() => setLoading(false));
    }

    public updatePlayer(saison: number, token: string, setUser: (u:User) => void, player:PlayerUpdateObj, setData: (d: TeamOverview) => void, setLoading: (p:boolean) => void): void  {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"member/team/updatePlayer", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authToken
            },
            method: "PUT",
            body: JSON.stringify(player)
        })
        .then(r => {
            if(r.ok) {
                return r.json() as Promise<ApiResponse<TeamOverview>>;
            } else {
                return Promise.reject(r);
            }
        })
        .then(r => setData(r.data))
        .catch(e => setUser({token: "", name: "", rolle: ""}))
        .finally(() => setLoading(false));
    }

    public addPlayer(saison: number, token: string, setUser: (u:User) => void, player:Player, setData: (d: TeamOverview) => void, setLoading: (p:boolean) => void): void  {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"member/team/addPlayer", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authToken
            },
            method: "POST",
            body: JSON.stringify(player)
        })
        .then(r => {
            if(r.ok) {
                return r.json() as Promise<ApiResponse<TeamOverview>>;
            } else {
                return Promise.reject(r);
            }
        })
        .then(r => setData(r.data))
        .catch(e => setUser({token: "", name: "", rolle: ""}))
        .finally(() => setLoading(false));
    }

    public addStaff(saison: number, token: string, setUser: (u:User) => void, staff:Staff, setData: (d: TeamOverview) => void, setLoading: (p:boolean) => void): void  {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"member/team/addStaff", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authToken
            },
            method: "POST",
            body: JSON.stringify(staff)
        })
            .then(r => {
                if(r.ok) {
                    return r.json() as Promise<ApiResponse<TeamOverview>>;
                } else {
                    return Promise.reject(r);
                }
            })
            .then(r => setData(r.data))
            .catch(e => setUser({token: "", name: "", rolle: ""}))
            .finally(() => setLoading(false));
    }
}