import React, {useEffect, useMemo, useState} from 'react';
import DataService from "../services/DataService";
import Select from "react-select";

const mapToDropDownSkill = (skills: SkillDto[]) => {
    const options: GroupedSkillOption[] = [];

    skills.forEach(skill => {
        let option = options.find(a => a.label === skill.typ);
        if(option === undefined) {
            option = {label: skill.typ, options: []};
            options.push(option);
        }
        option.options.push({value: skill.id, label: skill.name});
    });

    return options;
}

const mapToDefaultValue = (skills: SkillDto[], data: Skill[] | string | null):SkillSelectOption[] => {
    if(data === null) {
        return [];
    } else if(typeof data === 'string') {
        const values: SkillSelectOption[] = [];
        console.log(data);
        data.split(',').map(s => s.trim()).forEach((s) => {
            const skill = skills.find((e) => e.name === s);
            console.log('s:',s);
            console.log('skill:',skill);
            if(skill !== undefined) {
                values.push({value: skill.id, label: skill.name});
            }
        });
        console.log(values);
        return values;
    }
    return data.map((s) => { return {value: s.id, label: s.name}});
}

export default function SkillSelectbox(props: Readonly<SkillSelectProps>) {
    const service = useMemo(() => DataService.getInstance(), []);
    const [skills, setSkills] = useState<SkillDto[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        service.staticData().getSkills(setSkills, setLoading);
    }, [service]);

    return (
        loading ?
            <div className="spinner-grow" role="status"/>
        :
            <Select<SkillSelectOption, true, GroupedSkillOption>
                options={mapToDropDownSkill(skills)}
                isDisabled={props.disabled ?? false}
                defaultValue={mapToDefaultValue(skills, props.selected)}
                isMulti={true}
                onChange={props.onchange}
            ></Select>
    );
}