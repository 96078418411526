import React, {useEffect, useMemo, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import DataService from "../../services/DataService";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";
import {user as userAtom} from "../../atoms/user";
import Team from "../Team";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";


export default function Gegner() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useRecoilState(userAtom);
    const [teamInfo, setTeamInfo] = useState<TeamInfo>({id: 0, name: "", value: {all: 0, actual: 0}});

    // @ts-ignore
    const params: { saison: number } = useParams();

    useEffect(() => {
        if(user.token === "") {
            setLoading(true);
        } else {
            setLoading(true);
            service.dashboard().getGegner(params.saison, user.token, setUser, setTeamInfo, setLoading);
        }
    }, [service, user.token, params.saison, setUser]);

    return (
        <Container className={"greyBg"}>
            {
                loading ?
                    <div className="spinner-grow" role="status"/>
                :
                    <p>
                        <Row>
                            <Col><h5>Nächster Gegner</h5></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Team team={teamInfo}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>Teamwert: </Col>
                            <Col>{teamInfo.value.all}</Col>
                        </Row>
                        <Row>
                            <Col>Momentaner Teamwert: </Col>
                            <Col>{teamInfo.value.actual}</Col>
                        </Row>
                        <Row>
                            <Col>
                                Download PDF Daten: <a onClick={e => service.dashboard().getPrintDaten(params.saison, user.token, setUser)}><FontAwesomeIcon icon={faPrint}/></a>
                            </Col>
                        </Row>
                    </p>
            }
        </Container>);
}